
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    links: VueTypes.array,
    isLarge: VueTypes.bool,
  },
  computed: {
    activeSubNav() {
      return this.$store.state.nav.activeSubNav;
    },
  },
  methods: {
    showSubNav(index) {
      this.$store.commit('nav/showSubNav', index);
    },
    hideSubNav() {
      this.$store.commit('nav/hideSubNav');
    },
    hideNav() {
      this.$store.commit('nav/hideNav');
      this.$store.commit('scroll/unlockTracking');
    },
  },
};

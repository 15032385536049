import { render, staticRenderFns } from "./FooterDefault.vue?vue&type=template&id=f1e0d4ba&scoped=true"
import script from "./FooterDefault.vue?vue&type=script&lang=js"
export * from "./FooterDefault.vue?vue&type=script&lang=js"
import style0 from "./FooterDefault.vue?vue&type=style&index=0&id=f1e0d4ba&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1e0d4ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/workspace/app/components/icons/Icon.vue').default,FooterBlock: require('/workspace/app/components/footer/FooterBlock.vue').default,NewsletterSubscribeForm: require('/workspace/app/components/forms/NewsletterSubscribeForm.vue').default,Icon: require('/workspace/app/components/icons/Icon.vue').default,Footer: require('/workspace/app/components/footer/Footer.vue').default})

export default ({ app, store }, inject) => {
  function getActionPath(path) {
    const apiEndpoint = process.client
      ? process.env.CLIENT_API_HOST
      : process.env.SERVER_API_HOST;
    const url = `${apiEndpoint}/actions${path}`;
    return url;
  }

  function getCsrfToken() {
    if (!store.state.csrfToken?.value) {
      try {
        return app.$axios
          .$get(getActionPath('/main/csrf/token'))
          .then((token) => {
            if (token.name && token.value) {
              if (process.client) {
                store.commit('csrfToken', token);
              }
              return token;
            }
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      return store.state.csrfToken;
    }
  }

  async function fetch(method, path, payload, params) {
    const url = getActionPath(path);
    const token = await getCsrfToken();

    if (method === '$get') {
      return app.$axios[method](url, {
        ...params,
        headers: { 'X-CSRF-Token': token.value },
      });
    }

    return app.$axios[method](
      url,
      { ...payload, [token.name]: token.value },
      { ...params, headers: { [token.name]: token.value } },
    );
  }

  inject('get', (path, params) => {
    return fetch('$get', path, null, params);
  });

  inject('post', (path, payload, params) => {
    return fetch('$post', path, payload, params);
  });

  inject('put', (path, payload, params) => {
    return fetch('$put', path, payload, params);
  });
};

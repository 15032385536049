import VueTypes from 'vue-types';

import {
  LEFT,
  RIGHT,
  DOWN,
  UP,
  LEFTTOP,
  RIGHTTOP,
  LEFTBOTTOM,
  RIGHTBOTTOM,
} from '@/constants/Direction';

export const direction = VueTypes.oneOf([
  LEFT,
  RIGHT,
  DOWN,
  UP,
  LEFTTOP,
  RIGHTTOP,
  LEFTBOTTOM,
  RIGHTBOTTOM,
]);

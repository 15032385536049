
import { string, shape, arrayOf } from 'vue-types';
import { isNullableString } from '@/types/isNullable';
export default {
  inheritAttrs: false,
  props: {
    publicationId: string().isRequired,
    documents: arrayOf(
      shape({
        id: string(),
        heading: isNullableString(),
        countryCode: isNullableString(),
        document: arrayOf(
          shape({
            id: string(),
            title: string(),
            url: string(),
          }).loose,
        ),
      }).loose,
    ).isRequired,
  },
  data() {
    return {
      email: '',
      firstname: '',
      lastname: '',
      company: '',
      phone: '',
      error: '',
      loading: false,
      optIn: false,
      honeypot: '',
    };
  },
  computed: {
    downloadFormEntered() {
      return this.$store.state.downloadFormEntered;
    },
  },
  methods: {
    async onSubmit(e = null) {
      if (e) e.preventDefault();

      this.error = '';
      this.loading = true;

      try {
        const payload = {
          email_address: this.email,
          first_name: this.firstname,
          last_name: this.lastname,
          company: this.company,
          phone: this.phone,
          status: this.optIn ? 'subscribed' : 'transactional',
          tags: ['Nieuwsbrief', this.$site.toUpperCase()],
          'form-field': this.honeypot, // see craft-subscribe
        };

        await this.$post('/craft-subscribe/subscribe', payload);
      } catch (e) {
        if (e.response?.data?.error?.title !== 'Member Exists') {
          this.error =
            'Iets ging er niet goed bij het inschrijven, probeer het later nog eens.';
          console.error(e);
          this.$sentry.captureException(e);
        }
      }
      this.$store.dispatch('setCookieDownload');
      this.loading = false;
    },
  },
};

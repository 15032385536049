
import VueTypes from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';

export default {
  mixins: [buttonOrLink],
  inheritAttrs: false,
  props: {
    icon: VueTypes.string.def('arrow'),
    iconFill: VueTypes.bool.def(true),
    down: VueTypes.bool.def(false),
    label: VueTypes.string,
    variant: VueTypes.oneOf(['filled', 'bordered', 'filled-white']).def(
      'filled',
    ),
    nohover: VueTypes.bool.def(false),
    reverted: VueTypes.bool.def(false),
    partialRound: VueTypes.bool.def(false),
  },
};

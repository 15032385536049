import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64046d58 = () => interopDefault(import('../pages/actueel/index.vue' /* webpackChunkName: "pages/actueel/index" */))
const _991d9338 = () => interopDefault(import('../pages/artikelen/index.vue' /* webpackChunkName: "pages/artikelen/index" */))
const _fbc74b70 = () => interopDefault(import('../pages/geschiedenis/index.vue' /* webpackChunkName: "pages/geschiedenis/index" */))
const _e44bb896 = () => interopDefault(import('../pages/themas/index.vue' /* webpackChunkName: "pages/themas/index" */))
const _6b6810e8 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _82bfbf78 = () => interopDefault(import('../pages/actueel/publicaties/index.vue' /* webpackChunkName: "pages/actueel/publicaties/index" */))
const _862d0208 = () => interopDefault(import('../pages/actueel/publicaties/_slug.vue' /* webpackChunkName: "pages/actueel/publicaties/_slug" */))
const _b10ab146 = () => interopDefault(import('../pages/carriere/functieprofielen/_slug.vue' /* webpackChunkName: "pages/carriere/functieprofielen/_slug" */))
const _624dcc10 = () => interopDefault(import('../pages/actueel/_slug.vue' /* webpackChunkName: "pages/actueel/_slug" */))
const _9c8ad5c8 = () => interopDefault(import('../pages/artikelen/_slug.vue' /* webpackChunkName: "pages/artikelen/_slug" */))
const _a9079e62 = () => interopDefault(import('../pages/campagne-verhalen/_slug.vue' /* webpackChunkName: "pages/campagne-verhalen/_slug" */))
const _56d79efa = () => interopDefault(import('../pages/themas/_parentSlug/index.vue' /* webpackChunkName: "pages/themas/_parentSlug/index" */))
const _3d6a3846 = () => interopDefault(import('../pages/vacatures/_slug.vue' /* webpackChunkName: "pages/vacatures/_slug" */))
const _5520fdb2 = () => interopDefault(import('../pages/themas/_parentSlug/_slug.vue' /* webpackChunkName: "pages/themas/_parentSlug/_slug" */))
const _32422d1e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7dd51276 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/actueel",
    component: _64046d58,
    name: "actueel"
  }, {
    path: "/artikelen",
    component: _991d9338,
    name: "artikelen"
  }, {
    path: "/geschiedenis",
    component: _fbc74b70,
    name: "geschiedenis"
  }, {
    path: "/themas",
    component: _e44bb896,
    name: "themas"
  }, {
    path: "/zoeken",
    component: _6b6810e8,
    name: "zoeken"
  }, {
    path: "/actueel/publicaties",
    component: _82bfbf78,
    name: "actueel-publicaties"
  }, {
    path: "/actueel/publicaties/:slug",
    component: _862d0208,
    name: "actueel-publicaties-slug"
  }, {
    path: "/carriere/functieprofielen/:slug?",
    component: _b10ab146,
    name: "carriere-functieprofielen-slug"
  }, {
    path: "/actueel/:slug",
    component: _624dcc10,
    name: "actueel-slug"
  }, {
    path: "/artikelen/:slug",
    component: _9c8ad5c8,
    name: "artikelen-slug"
  }, {
    path: "/campagne-verhalen/:slug?",
    component: _a9079e62,
    name: "campagne-verhalen-slug"
  }, {
    path: "/themas/:parentSlug",
    component: _56d79efa,
    name: "themas-parentSlug"
  }, {
    path: "/vacatures/:slug?",
    component: _3d6a3846,
    name: "vacatures-slug"
  }, {
    path: "/themas/:parentSlug/:slug",
    component: _5520fdb2,
    name: "themas-parentSlug-slug"
  }, {
    path: "/",
    component: _32422d1e,
    name: "index"
  }, {
    path: "/*",
    component: _7dd51276,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

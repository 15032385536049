
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    links: VueTypes.array,
  },
  data() {
    return {
      toShowIndex: null,
    };
  },
  computed: {
    activeSubNav() {
      return this.$store.state.nav.activeSubNav;
    },
  },
  watch: {
    activeSubNav(newVal, oldVal) {
      if (oldVal && !newVal) {
        setTimeout(() => {
          this.toShowIndex = newVal;
        }, 150);
      } else {
        this.toShowIndex = newVal;
      }
    },
  },
  methods: {
    showSubNav(index) {
      this.$store.commit('nav/showSubNav', index);
    },
    hideSubNav() {
      this.$store.commit('nav/hideSubNav');
    },
  },
};

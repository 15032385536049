
export default {
  inheritAttrs: false,
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    activeSearch() {
      return this.$store.state.nav.showSearch;
    },
  },
  watch: {
    activeSearch(newVal, oldVal) {
      this.$nextTick(() => {
        if (newVal && !oldVal) {
          this.$refs.searchInput.focus();
        }
      });
    },
  },
  methods: {
    onSubmit(e) {
      if (!this.searchQuery || this.searchQuery.length < 3) {
        e.preventDefault();
      }
    },
    hideSearch() {
      this.$store.commit('nav/hideSearch');
      this.$store.commit('scroll/unlockTracking');

      this.$refs.searchInput.value = '';
    },
  },
};

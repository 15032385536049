export default {
  computed: {
    component() {
      if (this.$attrs.to) {
        return 'nuxt-link';
      }
      if (this.$attrs.href) {
        return 'a';
      }
      return 'button';
    },
  },
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    links: VueTypes.array,
    title: VueTypes.string,
    hideTitleOnDesktop: VueTypes.boolean,
  },

  data() {
    return {
      active: false,
    };
  },
};

import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=077ee2e6&scoped=true"
var script = {}
import style0 from "./Logo.vue?vue&type=style&index=0&id=077ee2e6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077ee2e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/workspace/app/components/icons/Icon.vue').default})

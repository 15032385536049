
export default {
  inheritAttrs: false,
  computed: {
    show() {
      return this.$store.state.overlay.downloadPublication !== null;
    },
    publicationId() {
      return this.$store.state.overlay.downloadPublication?.publicationId;
    },
    documents() {
      return this.$store.state.overlay.downloadPublication?.documents;
    },
  },
  methods: {
    closeOverlay() {
      this.$store.commit('overlay/closeOverlay');
    },
  },
};

// import { oneOfType } from 'vue-types';

// const nullableType = {
//   type: null,
//   validator(value) {
//     console.log('validating', value, typeof value);
//     return value === null;
//   },
// };

/**
 * Note: this compiles ok, but throws runtime errors.
 */
export default function isNullable(propType) {
  return [propType, null];
}

// export default function isNullable(propType) {
//   return oneOfType([propType, nullableType]);
// }

export function isNullableString() {
  return isNullable(String);
}

export function isNullableNumber() {
  return isNullable(Number);
}

export function isNullableObject() {
  return isNullable(Object);
}

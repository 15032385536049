export const state = () => ({
  downloadPublication: null,
});

export const mutations = {
  openDownload(state, downloadPublication) {
    state.downloadPublication = downloadPublication;
  },
  closeOverlay(state) {
    state.downloadPublication = null;
  },
};

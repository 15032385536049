
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    links: VueTypes.array,
    prefix: VueTypes.string,
  },
};

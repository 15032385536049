
import VueTypes from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';
import buttonIconSize from '@/mixins/buttonIconSize';
import { direction } from '@/types/direction';
import { RIGHT } from '@/constants/Direction';

export default {
  mixins: [buttonOrLink, buttonIconSize],
  inheritAttrs: false,
  props: {
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    type: VueTypes.oneOf(['bordered', 'filled']).def('bordered'),
    icon: VueTypes.string.def('arrow'),
    label: VueTypes.string,
    reverted: VueTypes.bool.def(false),
    hideLabelOnMobile: VueTypes.bool.def(false),
    direction: direction.def(RIGHT),
  },
};

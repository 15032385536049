export default {
  computed: {
    computedSize() {
      if (this.size === 'large') {
        return {
          icon: 14,
          circle: 80,
          class: 'large',
        };
      }
      if (this.size === 'medium') {
        return {
          icon: 14,
          circle: 64,
          class: 'medium',
        };
      } else {
        return {
          icon: 10,
          circle: 40,
          class: 'small',
        };
      }
    },
  },
};

import query from '@/gql/queries/globals.gql';

// DO NOT CHANGE! Used in DownloadController
export const COOKIE_NAME_DOWNLOAD = 'download-form';

export const COOKIE_VERSION = 1;
export const COOKIE_SETTINGS = {
  maxAge: 60 * 60 * 24 * 365, // 1 year
  sameSite: 'lax',
  secure: false,
  path: '/',
};

export const state = () => {
  return {
    csrfToken: null,
    downloadFormEntered: false,

    footerData: {},
    contact: {},
  };
};

export const mutations = {
  setGlobals(state, value) {
    state.footerData = value.footerData;
    state.contact = value.contact;
  },
  cookieDownload(state) {
    state.downloadFormEntered = true;
  },
  csrfToken(state, value) {
    state.csrfToken = value;
  },
};

export const actions = {
  async nuxtServerInit({ commit }) {
    const { nav, footerData, contact } = await this.$cms.query({
      query,
      variables: { site: this.$site },
    });

    commit('nav/update', nav);
    commit('setGlobals', {
      footerData,
      contact,
    });
  },
  setCookieDownload({ commit }) {
    if (process.client) {
      commit('cookieDownload');

      this.$cookies.set(
        COOKIE_NAME_DOWNLOAD,
        {
          version: COOKIE_VERSION,
        },
        COOKIE_SETTINGS,
      );
    }
  },
};

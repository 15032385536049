
import VueTypes from 'vue-types';
import buttonOrLink from '@/mixins/buttonOrLink';

export default {
  mixins: [buttonOrLink],
  inheritAttrs: false,
  props: {
    label: VueTypes.String,
    icon: VueTypes.String,
    size: VueTypes.number.def(19),
  },
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    links: VueTypes.array,
    socialLinks: VueTypes.array,
    prefix: VueTypes.string,
    loginUrl: VueTypes.string,
  },
  computed: {
    isActive() {
      return this.$store.state.nav.showNav;
    },
  },
  methods: {
    hideNav() {
      this.$store.commit('nav/hideNav');
      this.$store.commit('scroll/unlockTracking');
    },
  },
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    variant: VueTypes.oneOf(['filled', 'bordered', 'filled-white']).def(
      'filled',
    ),
    isCompleted: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
  },
};

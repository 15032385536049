
import { RIGHT } from '@/constants/Direction';
import { direction } from '@/types/direction';

export default {
  inheritAttrs: false,
  props: {
    direction: direction.def(RIGHT),
  },
};

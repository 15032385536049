
import VueTypes from 'vue-types';

export default {
  props: {
    error: VueTypes.object,
  },
  computed: {
    bodyText() {
      if (this.error.statusCode === 404) {
        return 'Deze pagina bestaat niet';
      }

      return 'Er is een fout opgetreden';
    },
  },
};


export default {
  inheritAttrs: false,
  computed: {
    contact() {
      return this.$store.state.contact;
    },
    footerData() {
      return this.$store.state.footerData;
    },
    siteTitle() {
      return this.$siteTitle;
    },
    cdnUrl() {
      return process.env.CDN_URL;
    },
  },
};

import { render, staticRenderFns } from "./FooterLinksMVM.vue?vue&type=template&id=95772394&scoped=true"
import script from "./FooterLinksMVM.vue?vue&type=script&lang=js"
export * from "./FooterLinksMVM.vue?vue&type=script&lang=js"
import style0 from "./FooterLinksMVM.vue?vue&type=style&index=0&id=95772394&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95772394",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./DownloadForm.vue?vue&type=template&id=2e9f436f&scoped=true"
import script from "./DownloadForm.vue?vue&type=script&lang=js"
export * from "./DownloadForm.vue?vue&type=script&lang=js"
import style0 from "./DownloadForm.vue?vue&type=style&index=0&id=2e9f436f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9f436f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/workspace/app/components/buttons/IconButton.vue').default,DownloadPublicationForm: require('/workspace/app/components/forms/DownloadPublicationForm.vue').default,BaseOverlay: require('/workspace/app/components/overlays/BaseOverlay.vue').default})

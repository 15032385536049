import Vue from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

let scrollTriggerContainer = null;

const GSAP = {
  install(Vue, options) {
    Vue.prototype.$gsap = gsap;
    Vue.prototype.$ScrollTrigger = ScrollTrigger;
    Vue.prototype.$ScrollTriggerContainer = {
      get: () => {
        return scrollTriggerContainer;
      },
      set: (tween) => {
        scrollTriggerContainer = tween;
      },
      remove() {
        if (!scrollTriggerContainer) return;
        scrollTriggerContainer.scrollTrigger?.kill();
        scrollTriggerContainer.kill();
        scrollTriggerContainer = null;
      },
    };
  },
};

Vue.use(GSAP);


import VueTypes from 'vue-types';
import IconArrow from '@/assets/icons/arrow.svg';
import IconCalendar from '@/assets/icons/calendar.svg';
import IconCheck from '@/assets/icons/check.svg';
import IconChevron from '@/assets/icons/chevron.svg';
import IconCircle from '@/assets/icons/circle.svg';
import IconClose from '@/assets/icons/close.svg';
import IconPlay from '@/assets/icons/play.svg';
import IconDownload from '@/assets/icons/download.svg';
import IconFacebook from '@/assets/icons/facebook.svg';
import IconHamburger from '@/assets/icons/hamburger.svg';
import IconLinkedin from '@/assets/icons/linkedin.svg';
import IconMail from '@/assets/icons/mail.svg';
import IconPaper from '@/assets/icons/paper.svg';
import IconSearch from '@/assets/icons/search.svg';
import IconTwitter from '@/assets/icons/twitter.svg';
import IconYoutube from '@/assets/icons/youtube.svg';

import IconLogo from '@/assets/icons/logo-vig.svg';
import IconLogoYim from '@/assets/icons/logo-yim.svg';
import IconLogoEN from '@/assets/icons/logo-vig-en.svg';

export default {
  inheritAttrs: false,
  props: {
    name: VueTypes.string.required,
    fill: VueTypes.bool,
    size: VueTypes.number,
    width: VueTypes.number,
    height: VueTypes.number,
  },
  computed: {
    svgIcon() {
      return {
        arrow: IconArrow,
        calendar: IconCalendar,
        check: IconCheck,
        chevron: IconChevron,
        circle: IconCircle,
        close: IconClose,
        play: IconPlay,
        download: IconDownload,
        facebook: IconFacebook,
        hamburger: IconHamburger,
        linkedin: IconLinkedin,
        mail: IconMail,
        paper: IconPaper,
        search: IconSearch,
        twitter: IconTwitter,
        youtube: IconYoutube,
        'logo-vig': IconLogo,
        'logo-yim': IconLogoYim,
        'logo-vig-en': IconLogoEN,
      }[this.name];
    },
    computedWidth() {
      return this.width || this.size;
    },
    computedHeight() {
      return this.height || this.size;
    },
    hasSize() {
      return this.computedWidth && this.computedHeight;
    },
    classes() {
      return {
        fill: this.fill,
        full: this.hasSize,
      };
    },
    style() {
      if (this.hasSize) {
        return {
          width: `${this.computedWidth}px`,
          height: `${this.computedHeight}px`,
        };
      }
      return null;
    },
  },
};

import { render, staticRenderFns } from "./ParentLogo.vue?vue&type=template&id=6a7fd92e&scoped=true"
var script = {}
import style0 from "./ParentLogo.vue?vue&type=style&index=0&id=6a7fd92e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7fd92e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/workspace/app/components/icons/Icon.vue').default})

import { render, staticRenderFns } from "./NavLinks.vue?vue&type=template&id=5a75ed48&scoped=true"
import script from "./NavLinks.vue?vue&type=script&lang=js"
export * from "./NavLinks.vue?vue&type=script&lang=js"
import style0 from "./NavLinks.vue?vue&type=style&index=0&id=5a75ed48&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a75ed48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/workspace/app/components/icons/Icon.vue').default,Arrow: require('/workspace/app/components/icons/Arrow.vue').default})

export const state = () => ({
  outroStarted: false,
  outroDone: false,
});

export const mutations = {
  outroStarted(state) {
    state.outroStarted = true;
  },
  outroDone(state) {
    state.outroDone = true;
  },
};

// const Color = require('color');

// const alpha = (clr, val) => Color(clr).alpha(val).rgb().string();
// const lighten = (clr, val) => Color(clr).lighten(val).rgb().string();
// const darken = (clr, val) => Color(clr).darken(val).rgb().string();

// const PRIMARY = '#00f8b3';
// const SECONDARY = '#d360ff';

const colors = {
  current: 'currentColor',
  // primary: PRIMARY,
  // secondary: SECONDARY,
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  grey: {
    default: '#8c8c8c',
    light: '#e5e5e5',
    medium: '#F5F5F3',
    dark: '#7A7A7A',
  },
  blue: {
    default: '#054277',
    dark: '#001E62',
    pastel: '#BBDFE6',
    'light-a': '#0F5ADC',
    'light-b': '#00ADE8',
    700: '#000A7D',
    600: '#002395',
    500: '#0040B3',
    400: '#0752C7',
    300: '#6C87DA',
    200: '#C2CAEF',
    100: '#C2CAEF',
    50: '#F9F9FE',
  },
  red: {
    default: '#F42740',
    ui: '#f6263f',
    'orange-a': '#F10048',
    'orange-b': '#FF9300',
  },
  orange: '#FF6B33',
  yellow: {
    pastel: '#FFEAD3',
  },
  green: {
    default: '#015F51',
    pastel: '#CFD2A1',
    ui: '#3fb560',
  },
  aubergine: {
    default: '#70004C',
    pastel: '#F7C5DB',
  },
  purple: {
    a: '#8E2DE2',
    b: '#4A00E0',
  },
};

module.exports = colors;

export default function ({ req, $gtm, app: { router } }, inject) {
  const currentHost = process.server
    ? req.headers.host
    : window?.location?.host;

  // VIG SITE
  if (currentHost === process.env.VIG_SITE_HOST) {
    $gtm.init('GTM-5FVRFLB');

    inject('gtagEvent', (_action, _category, _label) => {
      // Placeholder to prevent errors.
    });
  }

  // YIM SITE
  if (currentHost === process.env.YIM_SITE_HOST) {
    $gtm.init('GTM-K4D2QJLQ');

    inject('gtagEvent', (_action, _category, _label) => {
      // Placeholder to prevent errors.
    });
  }

  // MVM SITE
  if (currentHost === process.env.MVM_SITE_HOST) {
    const gaId = 'UA-90458319-1';

    // Abort if we already added script to head
    const gtagScript = document.getElementById('gtag');
    if (gtagScript) {
      return;
    }

    // Add script tag to head
    const script = document.createElement('script');
    script.async = true;
    script.id = 'gtag';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    document.head.appendChild(script);

    // Include Google gtag code and inject it (so this.$gtag works in pages/components)
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line no-undef
      dataLayer.push(arguments);
    }

    inject('gtag', gtag);

    gtag('js', new Date());
    gtag('config', gaId, { send_page_view: false });

    inject('gtagEvent', (action, category, label) => {
      gtag('event', action, {
        event_category: category,
        event_label: label,
      });
    });

    // After each router transition, log page event to Google for each code
    router.afterEach((to) => {
      gtag('config', gaId, {
        page_location: window.location.origin + to.fullPath,
        page_path: to.fullPath,
      });
    });
  }
}


import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';
import { COOKIE_NAME_DOWNLOAD, COOKIE_SETTINGS, COOKIE_VERSION } from '~/store';

export default {
  transition: 'main',
  computed: {
    theme() {
      return `theme-${this.$site}`;
    },
    isLocked() {
      return this.$store.state.scroll.locked;
    },
  },
  watch: {
    isLocked(value) {
      if (value) {
        disableBodyScroll(document.body);
      } else {
        enableBodyScroll(document.body);
      }
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  mounted() {
    this.$nextTick(() => {
      this.getDownloadCookie();
      this.setupResizeObserver();
      this.addScrollListeners();
    });
  },
  methods: {
    setupResizeObserver() {
      const ro = new ResizeObserver((entries, observer) => {
        this.$ScrollTrigger.sort();
        this.$ScrollTrigger.refresh();
      });
      ro.observe(this.$refs.scrollContainer);
    },
    addScrollListeners() {
      this.$nuxt.$on('scrollTop', () => {
        this.$gsap.set(window, { scrollTo: 0 });
        this.$ScrollTrigger.refresh();
      });

      this.$nuxt.$on('scrollTo', (target, duration) => {
        this.$gsap.to(window, { duration, scrollTo: target });
      });
    },

    // This cannot be varnish cached
    getDownloadCookie() {
      let cookieDownload = this.$cookies.get(COOKIE_NAME_DOWNLOAD);
      if (cookieDownload && cookieDownload.version !== COOKIE_VERSION) {
        this.$cookies.remove(COOKIE_NAME_DOWNLOAD, COOKIE_SETTINGS);
        cookieDownload = null;
      } else if (cookieDownload) {
        this.$store.commit('cookieDownload');
      }
    },
  },
};

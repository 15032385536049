
import VueTypes from 'vue-types';
import colors from '~/_tailwind/tailwind.config.colors';

export default {
  inheritAttrs: false,
  props: {
    fillColor: VueTypes.string.def(colors.orange),
    textColor: VueTypes.string.def(colors.white),
    gradientColor: VueTypes.string.def('#F42740'),
    strokeColor: VueTypes.string.def('#FFF'),
    hasStroke: VueTypes.bool.def(false),
    hasGradient: VueTypes.bool.def(false),
    hasFill: VueTypes.bool.def(false),
    overflowVisible: VueTypes.bool.def(false),
    shapeWidth: VueTypes.string,
  },
  computed: {
    cssProps() {
      return {
        '--shape-fill-color': this.fillColor,
        '--shape-gradient-color': this.gradientColor,
        '--shape-stroke-color': this.strokeColor,
      };
    },
    classes() {
      return {
        fill: this.hasFill,
        stroke: this.hasStroke,
        gradient: this.hasGradient,
      };
    },
  },
};

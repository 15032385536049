
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    small: VueTypes.bool.def(false),
    medium: VueTypes.bool.def(false),
    fullSmall: VueTypes.bool.def(true),
  },
};

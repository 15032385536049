export default ({ req, $sentry }, inject) => {
  const currentHost = process.server
    ? req.headers.host
    : window?.location?.host;

  try {
    const [handle, title] = {
      [process.env.VIG_SITE_HOST]: [
        'vig',
        'Vereniging Innovatieve Geneesmiddelen',
      ],
      [process.env.YIM_SITE_HOST]: ['yim', 'Young Innovators of Medicines'],
      [process.env.MVM_SITE_HOST]: ['mvm', 'Medicijnen voor morgen'],
    }[currentHost];

    inject('site', handle);
    inject('siteTitle', title);
  } catch (e) {
    if (process.env.ENVIRONMENT !== 'development') {
      $sentry.captureException(e);
      inject('site', 'NONE');
      inject('siteTitle', '');
      // inject('site', 'vig');
      // inject('siteTitle', 'Vereniging Innovatieve Geneesmiddelen');
    }
  }
};

export const state = () => ({
  width: 0,
  height: 0,
  availWidth: 0,
  availHeight: 0,
});

export const mutations = {
  update(state, viewport) {
    state.width = viewport.width;
    state.height = viewport.height;
    state.availWidth = viewport.availWidth;
    state.availHeight = viewport.availHeight;
  },
};

import { render, staticRenderFns } from "./FooterMVM.vue?vue&type=template&id=74a0c084&scoped=true"
import script from "./FooterMVM.vue?vue&type=script&lang=js"
export * from "./FooterMVM.vue?vue&type=script&lang=js"
import style0 from "./FooterMVM.vue?vue&type=style&index=0&id=74a0c084&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a0c084",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/workspace/app/components/nav/Logo.vue').default,FooterLinksMVM: require('/workspace/app/components/footer/FooterLinksMVM.vue').default,NavSocialMedia: require('/workspace/app/components/nav/NavSocialMedia.vue').default,SearchBox: require('/workspace/app/components/nav/SearchBox.vue').default,ParentLogo: require('/workspace/app/components/nav/ParentLogo.vue').default,Footer: require('/workspace/app/components/footer/Footer.vue').default})

import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2883a7fc"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterMVM: require('/workspace/app/components/footer/FooterMVM.vue').default,FooterDefault: require('/workspace/app/components/footer/FooterDefault.vue').default})

import unique from '@/utils/array/unique';

const first = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[0];
};

const last = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[arr.length - 1];
};

const cdnUrl = (filePath) => `${process.env.CDN_URL}${filePath}`;

export default (context, inject) => {
  // Inject in Vue, context and store.
  inject('first', first);
  inject('last', last);
  inject('unique', unique);
  inject('cdnUrl', cdnUrl);
  inject('relativeUrl', (uri) => {
    if (uri === '__home__') {
      return '/';
    }
    return `/${uri}`;
  });
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    cover: VueTypes.bool.def(false),
    hover: VueTypes.bool.def(false),
    kind: VueTypes.string.isRequired,
    videoplayer: VueTypes.bool.def(false),
    placeholder: VueTypes.string,
    showVideoControls: VueTypes.bool.def(false),
  },
  computed: {
    visualComponent() {
      if (this.videoplayer === true || this.showVideoControls) {
        return 'VideoPlayer';
      } else if (this.kind === 'image') {
        return 'v-image';
      } else if (this.kind === 'video') {
        return 'static-video';
      }
      return null;
    },
  },
};


import VueTypes from 'vue-types';
import colors from '@/_tailwind/tailwind.config.colors.js';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    body: VueTypes.string,
    visual: VueTypes.arrayOf({
      kind: VueTypes.string,
    }),
    // buttonText: VueTypes.string,
    buttonLabel: VueTypes.string,
    // button: VueTypes.object,
    small: VueTypes.bool.def(false),
  },
  data() {
    return { colors };
  },
  methods: {
    onScrollButtonClick() {
      const el = document.querySelector('.module');
      this.$nuxt.$emit('scrollTo', el.getBoundingClientRect().top - 112, 1);
    },
  },
};

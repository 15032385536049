export const state = () => ({
  locked: false,
});

export const mutations = {
  lockTracking(state) {
    state.locked = true;
  },
  unlockTracking(state) {
    state.locked = false;
  },
};

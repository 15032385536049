export const state = () => ({
  nav: null,
  activeSubNav: null,
  showNav: false,
  navLight: false,
  showSearch: false,
});

export const mutations = {
  update(state, value) {
    state.nav = value;
  },
  showSubNav(state, value) {
    state.activeSubNav = value;
    state.showSearch = false;
  },
  hideSubNav(state) {
    state.activeSubNav = null;
  },
  showNav(state) {
    state.showNav = true;
    state.showSearch = false;
  },
  hideNav(state) {
    state.showNav = false;
    state.activeSubNav = null;
  },
  navLight(state) {
    state.navLight = true;
  },
  navDark(state) {
    state.navLight = false;
  },
  showSearch(state) {
    state.showSearch = true;
  },
  hideSearch(state) {
    state.showSearch = false;
  },
};


export default {
  inheritAttrs: false,
  computed: {
    nav() {
      return this.$store.state.nav.nav;
    },
    activeSubNav() {
      return this.$store.state.nav.activeSubNav;
    },
    navLight() {
      return this.$store.state.nav.navLight;
    },
    activeNav() {
      return this.$store.state.nav.showNav;
    },
    activeSearch() {
      return this.$store.state.nav.showSearch;
    },
  },
  methods: {
    hideSubNav() {
      this.$store.commit('nav/hideSubNav');
      this.$store.commit('scroll/unlockTracking');
    },
    showNav() {
      this.$store.commit('nav/showNav');
      this.$store.commit('scroll/lockTracking');
    },
    hideNav() {
      this.$store.commit('nav/hideNav');
      this.$store.commit('scroll/unlockTracking');
    },
    toggleSearch() {
      if (this.activeSearch) {
        this.$store.commit('nav/hideSearch');
        this.$store.commit('scroll/unlockTracking');
      } else {
        this.$store.commit('nav/showSearch');
        this.$store.commit('scroll/lockTracking');
      }
    },
  },
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    label: VueTypes.string,
    parent: VueTypes.object,
    links: VueTypes.array,
  },
  methods: {
    hideSubNav() {
      this.$store.commit('nav/hideSubNav');
    },
  },
};


import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    portal: VueTypes.string.def('overlay'),
    position: VueTypes.oneOf(['right', 'center']).def('right'),
    value: VueTypes.bool.def(false),
    closable: VueTypes.bool.def(false),
  },
  computed: {},
  watch: {
    value(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.onOpen();
      } else if (!newVal && oldVal) {
        this.onClose();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.onOpen();
      }
    });
  },
  beforeDestroy() {
    this.onClose();
  },
  methods: {
    closeOverlay(e) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (this.closable) {
        this.$emit('input', false);
      }
    },
    onKeyPress({ key }) {
      if (key === 'Escape') {
        this.closeOverlay();
      }
    },
    onOpen() {
      this.$store.commit('scroll/lockTracking');
      window.addEventListener('keyup', this.onKeyPress);
    },
    onClose() {
      this.$store.commit('scroll/unlockTracking');
      window.removeEventListener('keyup', this.onKeyPress);
    },
  },
};

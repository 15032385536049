
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    idPrefix: VueTypes.string.def(''),
  },
  data() {
    return {
      isCompleted: false,
      email: '',
      error: '',
      loading: false,
      honeypot: '',
    };
  },
  computed: {
    fieldId() {
      return `${this.idPrefix}-email`;
    },
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();

      if (!this.email || !/[^@]+@[^@]+\.[^@]+/.test(this.email)) {
        this.error = 'Het lijkt er op dat dit geen correct e-mailadres is.';
        return;
      }

      this.error = '';
      this.loading = true;
      // this.$nuxt.$loading.start();

      try {
        const payload = {
          email_address: this.email,
          status: 'subscribed',
          tags: ['Nieuwsbrief', this.$site.toUpperCase()],
          'form-field': this.honeypot, // see craft-subscribe
        };

        await this.$post('/craft-subscribe/subscribe', payload);
        this.isCompleted = true;
      } catch (e) {
        if (e.response?.data?.error?.title === 'Member Exists') {
          this.isCompleted = true;
        } else {
          this.error =
            'Iets ging er niet goed bij het inschrijven, probeer het later nog eens.';
          console.error(e);
          this.$sentry.captureException(e);
        }
      } finally {
        this.loading = false;
        // this.$nuxt.$loading.finish();
      }
    },
  },
};

export default ({ app: { store } }) => {
  const viewport = {
    storeValues() {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
      };
      store.commit('viewport/update', size);
    },
    onResize() {
      window.requestAnimationFrame(this.storeValues);
    },
    listenForResize() {
      window.addEventListener('resize', this.onResize.bind(this));
    },
    init() {
      this.storeValues();
      this.listenForResize();
    },
  };

  viewport.init();
};


export default {
  inheritAttrs: false,
  mounted() {
    this.$ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.$nuxt.$emit('scrollTop');
  },
};

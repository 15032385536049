export const LEFT = 'l';
export const RIGHT = 'r';
export const UP = 'u';
export const DOWN = 'd';

export const LEFTTOP = 'lt';
export const RIGHTTOP = 'rt';
export const LEFTBOTTOM = 'lb';
export const RIGHTBOTTOM = 'rb';

export const HORIZONTAL = 'h';
export const VERTICAL = 'v';
